.heading {
    width: 40%;
    height: 2.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.text {
    height: 1.5rem;
    border-radius: 5px;
    margin-bottom: .5rem;
}

.list-group-item .text {
    margin-bottom: 0;
}

.topic {
    height: 1.5rem;
}

.keyNote {
    width: 25px;
    height: 1.5rem;
    border-radius: 5px;
    margin-right: .5rem;
}
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
    padding-top: 0rem;
    padding-bottom: 3rem;
    color: #5a5a5a;
    font-size: 17px;
    font-weight: 300;

}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel, .contentblock {
    margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    bottom: 3rem;
    z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
    height: 40rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 40rem;
}

@media (max-width: 768px) {

    .carousel-item, .carousel-item > img {
        height: 20rem;
    }

}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 0;
    text-align: center;
}
.marketing h2 {
    font-weight: 400;
}
.marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}




/* Featurettes
------------------------- */

.featurette-divider {
    margin: 4rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4;
    }

    .featurette-heading {
        font-size: 1.75rem;
    }
}

@media (min-width: 62em) {
    .featurette-heading {
        font-size: 1.75rem;
    }
}

.carousel-caption {
    color: #000;
    text-align: center;
}

.bd-placeholder-img
{
    background-repeat: no-repeat;
    background-size: cover;
    width: 140px;
    height: 140px;
    border: 0.2rem solid #5a5a5a;
    background-color: #f2f2f2;
}

.bg-dark
{
    background-color: #22588d !important;
}

.list-group p
{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
}

.list-group .font-weight-bold
{
    padding-left: 0.2rem;
}

.img-spacer
{
    padding-top: 0.9rem;
}

.text-spacer
{
    padding-bottom: 0.9rem;
}

.card-spacer
{
    padding-bottom: 30px;
}

.label
{
    padding-top: 0.5rem;
    padding-left: 1rem;
}

.label2
{
    padding-top: 0.3rem;
    padding-left: 0.8rem;
}

.custom-control-label::before, .custom-control-label::after
{
    height:1.5rem !important;
    width: 1.5rem !important;
}

.check-margin
{
    margin-top: 0.5rem;
}

.logo
{
    padding: 0.2rem;
    height: 40px;
}

.siegel-self-on
{
    border-color: green;
    font-size: 5rem;
    text-align: center;
    vertical-align: middle;
    line-height: 125px;
    color: green;
    background-color: rgba(0, 128, 0, 0.43);
}

.btn-primary.disabled, .btn-primary:disabled
{
    color: #fff;
    background-color: #55a7ff;
    border-color: #b3b3b3;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #e5e5e5;
    background: linear-gradient(to right, #dedede 8%, #d2d0d0 18%, #dedede 33%);
    background-size: 1000px 104px;
    position: relative;
}




.textfade-1-100 {
    animation: 1.5s ease-in 0.3s normal both infinite running textfade;
}


@keyframes textfade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

.lbdanger
{
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.toptolink {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.toptolink:hover {
    color: #0056b3;
    text-decoration: underline;
}



/* radio style */

/* Customize the label (the container) */
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #D6D8DB;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark-radio {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark-radio {
    background-color: #007bff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark-radio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark-radio:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* radio style Ende */

.badge.badge-dark {
    margin-right: .3rem;
}

/* darkmode */

@media (prefers-color-scheme: dark) {
    body, .list-group-item
    {background-color: #353436;
        color: #fff;
    }

    .badge-dark {
        color: #343a40;
        background-color: #fff;
    }


    .list-group-item, hr, .bd-placeholder-img
    {
        border-color: #686868;
    }

    .list-group-item-secondary
    {
        background-color: #717171;
    }

}

/* darkmode */